import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from './assets//logo.jpg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
		        <Preloader />
        <div className="header">
          <div className="logo">
            <a href="."> <img src={logo}  border="0" alt="Coming Soon" align="left"/></a>
          </div>
          <div className="social">
		<a href="mailto:office@dirtysouthapparel.com" title="Send us an email">
			<i class="fa fa-envelope" aria-hidden="true"></i>
		</a>
		<a href="tel:+1 (954) 579-4555" title="Call Us">
				<i class="fa fa-phone" aria-hidden="true"></i>
		</a>
          </div>
        </div>
		   <Timer />
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
        <div className="footer">
          <span>Powered by <a className="underlined" href="https://interpersonal.ro/" target="_blank" rel="noopener noreferrer">Interpersonal</a> </span>
        </div>
        </div>

      </div>
    );
  }
}

export default App;